


<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg2 pt-4 text-center>
        <span
          class="mainfont"
          style="color: black; font-size: 23px; font-weight: bolder"
        >
          Blogs</span
        >
        <v-flex pl-8 xs10 align-self-center>
          <v-progress-linear
            align-center
            height="4"
            color="#13736f"
            value="100"
            width="20%"
          ></v-progress-linear>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-2>
      <v-flex xs1 style="border-bottom: 6px solid grey"></v-flex>
      <v-flex xs2 style="border-bottom: 6px solid #8bc34a"></v-flex>
      <v-flex xs6 style="border-bottom: 6px solid #13736f"></v-flex>
      <v-flex xs2 style="border-bottom: 6px solid #8bc34a"></v-flex>
      <v-flex xs1 style="border-bottom: 6px solid grey"></v-flex>
    </v-layout>

    <v-layout wrap justify-end>
      <v-flex xs3 pt-8>
        <v-btn dark color="#13736f" @click="dialog2 = true"
          ><span
            style="
              font-family: mainfont;
              font-size: 15px;
              color: white;
              letter-spacing: 1px;
              cursor: pointer;
            "
            >Add
          </span>
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap
      ><v-flex
        xs12
        lg4
        pa-6
        v-for="(item, i) in list"
        :key="i"
        class="mainfont"
        style="color: white; font-size: 15px; font-weight: lighter"
      >
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <v-img
                @click="$router.push('/BlogDetailedpage?id=' + item._id)"
                contain
                height="200px"
                :src="mediaURL + item.photos[0]"
              >
              </v-img>
            </v-flex>
            <v-flex xs10>
              {{ item.content.slice(0, 118) }}
            </v-flex>
            <v-flex xs8 pa-3 align-self-center>
              <v-layout wrap justify-center fill-height>
                <v-flex xs4>
                  <v-btn
                    class="mx-2"
                    fab
                    elevation="0"
                    color="#EFFFFA"
                    @click="(editdailog = true), (curid = item)"
                  >
                    <v-icon color="black" size="130%" dark> mdi-pencil </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs4 pl-3>
                  <v-btn
                    class="mx-2"
                    fab
                    elevation="0"
                    color="#EFFFFA"
                    @click="(deletedialog = true), (curid = item._id)"
                  >
                    <v-icon color="red" dark size="130%"> mdi-delete </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card class="cus2">
        <v-card-title
          class="mainfont"
          style="color: white; font-size: 18px; font-weight: lighter"
          >Are you sure you want to delete this Blog?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="true"
      persistent
      v-model="dialog2"
      max-width="800px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex
            ><v-layout class="cus" wrap justify-center>
              <v-flex xs8 pa-4 text-center>
                <span class="mainfont"> <strong>Add Blog</strong></span>
              </v-flex>
              <v-flex md12 xs12> </v-flex>
              <v-flex xs8 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px"
                  >Title</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    placeholder="Title"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="title"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs8 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px"
                  >Content</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    placeholder="Content"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="content"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>
              <v-flex pt-3 xs8>
                <Images
                  @stepper="winStepper"
                  :height="'800'"
                  :width="'1300'"
                  :heading="'Upload Images'"
                />
              </v-flex>
              <v-flex xs5 text-center pa-4>
                <v-btn
                  small
                  rounded
                  color="white"
                  dark
                  @click="dialog2 = false"
                >
                  <span class="mainfont" style="color: red"> CANCEL </span>
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs5 text-center pa-4>
                <v-btn small rounded color="white" dark @click="add()">
                  <span class="mainfont" style="color: #13736f"> ADD </span>
                  <v-icon color="#13736f">mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="editdailog"
      max-width="800px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex
            ><v-layout wrap justify-center class="cus">
              <v-flex xs8 pa-4 text-center>
                <span class="mainfont"> <strong>Edit Blog</strong></span>
              </v-flex>
              <v-flex xs8 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px"
                  >Title</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    placeholder="Name"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.title"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs8 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px"
                  >Content</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    placeholder="Listing Order"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.content"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex xs5 pa-3 v-for="(item, i) in curid.photos" :key="i">
                <v-img :src="mediaURL + item"></v-img>
              </v-flex>
              <v-flex xs8 pa-2>
                <Images
                  @stepper="winStepper"
                  :height="'800'"
                  :width="'1300'"
                  :heading="'Upload Images'"
                />
              </v-flex>
              <v-flex xs5 text-center pa-4>
                <v-btn
                  small
                  rounded
                  color="white"
                  dark
                  @click="editdailog = false"
                >
                  <span class="mainfont" style="color: red"> CANCEL </span>
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs5 text-center pa-4>
                <v-btn small rounded color="white" dark @click="edit()">
                  <span class="mainfont" style="color: #13736f"> EDIT </span>
                  <v-icon color="#13736f">mdi-edit</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import ImageComp from "@/components/Common/singleImage";
import Images from "@/components/Common/multipleImages";
export default {
  props: ["id"],

  components: {
    // ImageComp,
    Images,
  },
  data() {
    return {
      title: null,
      content: [],
      photos: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialog2: false,
      deletedialog: false,
      imageArray: [],
      imageError: "",
      editdailog: false,
      list: [],
      imageCoverError: "",
      formData: new FormData(),
      formData2: new FormData(),
      selectedFiles: [],
      curid: [],
      image: null,
      coverImageArray: [],
      appLoading: false,
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    deleteItem() {
      axios({
        url: "/admin/blog/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "AddPackage") {
        this.slotAddition = window_data.slotAddition;
        this.packageRate = window_data.response;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      } else if (window_data.type == "product top image") {
        this.producttopimagefile = window_data.selectedFiles;
      } else if (window_data.type == "product right image") {
        this.productrightimagefile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.home.top.title) {
        this.msg = "Please Provide Top title";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      console.log("imgfiles=", this.imageFiles);
      this.formData.append("title", this.title);
      this.formData.append("content", this.content);
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }

      axios({
        method: "post",
        url: "/admin/blog/add",
        data: this.formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    edit() {
      console.log("imgfiles=", this.imageFiles);
      this.formData2.append("title", this.curid.title);
      this.formData2.append("id", this.curid._id);
      this.formData2.append("content", this.curid.content);
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData2.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }
      axios({
        method: "post",
        url: "/admin/blog/edit",
        data: this.formData2,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("programme", item);
      axios({
        method: "POST",
        url: "/programme/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showSnackBar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadImage() {
      this.appLoading = true;
      this.formDataCover.append("image", this.topimage);
      axios({
        method: "POST",
        url: "/home/image",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blog/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}

.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus {
  background-color: #13736f;
}
</style>